/* REGISTER PLANS PAGE */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Reddit+Sans:ital,wght@0,200..900;1,200..900&family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

.container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Inter', sans-serif !important;
}

.headContainer {
  height: fit-content;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  text-align: center;
  background: #006fda;
  padding: 32px 0;
  color: #fff;
}

.pageHeading {
  font-weight: 800;
  text-align: center;
  line-height: 52px;
  font-size: 46px;
}
.headContainer ul {
  width: 70%;
  max-width: 800px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.headContainer li {
  list-style: none;
  display: flex;
  align-items: center;
}

.planContainer {
  height: fit-content;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.planWrapper {
  max-width: 1089px;
  color: #333;
  padding-bottom: 4rem;
}
.filterContainer {
  display: flex;
  justify-content: flex-end;
  margin: 17px 0 !important;
}
.text {
  font-size: 14px;
  line-height: 35px;
  font-weight: 600;
}
.planListContainer {
  width: 100%;
  height: fit-content;
  background-color: white;
  justify-content: center;
}
.planItemContainer {
  min-width: 363px;
  border: 1px solid #e7ebf1;
  text-align: center;
}
.planContainerHeilight {
  border-radius: 10px;
  transform: scaleY(1.023);
  border: 2px solid #f3cb71;
  border-top: 13px solid #f3cb71;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.planContainerLeft {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.planContainerRight {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.planItemContainer h2 {
  font-size: 14px;
  letter-spacing: 3px;
  padding: 13px 0;
  border-bottom: 1px solid #e7ebf1;
}
.note {
  font-size: 14px;
  line-height: 23px;
  max-width: 215px;
  margin: auto;
  padding: 20px 0;
  color: #333333;
  font-weight: 450;
}
.planPriceContainer {
  min-height: 81px;
  max-height: 81px;
  transition: all 0.5s ease;
}
.planPriceOff {
  font-size: 16px;
  line-height: 16px;
  opacity: 0.5;
  text-decoration: line-through;
  display: block;
}
.planPriceOn {
  font-size: 46px;
  font-weight: 700;
  padding: 10px 0;
}
.priceNote {
  color: #7b7b7b;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 450;
  display: block;
}
.line {
  height: 0.5px;
  background-color: #e7ebf1;
  border: none;
}
.btn {
  background-color: #f5ba31;
  padding: 18px 0;
  width: 225px;
  border-radius: 5px;
  font-weight: 700;
  color: #000;
  font-size: 14px;
  margin-bottom: 15px;
  border: none;
  cursor: pointer;
}
.featuresContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  min-height: 580px;
  justify-content: space-between;
}
.listContainer {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-inline-start: 0;
  padding-left: 30px;
}
.featureName {
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 24px;
  font-size: 13px;
  position: relative;
  font-weight: 450;
  padding: 8px 29px 0 0;
}
.iconSmall {
  margin: 3px 7px 0 0 !important;
  font-size: 1.1rem !important;
}
.planFooter {
  padding: 0 0 8px 30px;
  border-top: 1px solid #e7ebf1;
  width: 100%;
}
.link {
  color: #838383;
}

.boxContainer {
  max-width: 1089px;
  border-radius: 10px;
  border: 1px solid #e7ebf1;
  display: flex;
  align-items: center;
  padding: 50px;
  margin: 75px auto auto !important;
  background-color: #fff;
}
.freeImg {
  max-width: 230px;
  height: auto;
  margin-right: 50px;
  margin-top: -25px;
}
.trailFeatureContainer {
  width: 100%;
}
.header2 {
  font-size: 28px;
  padding: 10px 0;
  font-weight: 700;
}
.trailFeatureContainer p {
  font-weight: 500;
  color: #838383;
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7ebf1;
  margin-bottom: 15px;
}
.trialFeatureList {
  max-height: 250px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-inline-start: 0;
}

.addonsContainer {
  background-color: #000;
  padding: 50px 0;
}
.addonsDetails {
  max-width: 1112px;
  margin: auto;
  text-align: center !important;
}
.addonsDetails h2 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  padding: 40px 0;
  color: #fff;
}
.addonWrapper {
  height: 216px;
}
.addonCard {
  height: 100%;
  width: 100%;
  border: 1px solid #272627;
  border-radius: 8px;
  background-color: #111010;
  color: white;
}
.cardWrapper {
  display: flex;
  padding: 15px;
  flex-direction: column !important;
  align-items: flex-start;
}
.addonHeadContainer {
  display: flex;
  align-items: center;
}
.addonHeadContainer span {
  max-width: 182px;
  font-weight: 600;
  margin-left: 10px;
  line-height: 17px;
}
.addonHeaderIcon {
  color: #7b7b7b;
  padding-bottom: 3px;
}
.addonPrice {
  font-size: 28px;
  font-weight: 500;
  padding: 18px 0 8px;
}
.addonLimit {
  font-size: 14px;
  line-height: normal;
  margin-top: 6px;
}
.billingMethod {
  margin-top: 6px;
  color: #878787;
  transition: all 0.2s ease;
  font-size: 13px;
}
.addonMsg {
  line-height: 26px;
  margin-top: 12px;
  color: #878787;
  font-size: 14px;
}
.addonDeclaimer {
  text-align: left;
  padding: 20px 0 0;
}
.addonDeclaimer p {
  font-size: 12px;
  font-style: italic;
  margin: 25px auto;
  color: #fff;
}
.contactBG {
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  color: #292929;
}
.contactWrapper {
  max-width: 1112px;
  height: fit-content;
}
.contactInfo {
  height: 650px;
  padding: 5.5% 30px !important;
  display: flex;
  flex-direction: column !important;
}
.sentMessage {
  position: relative;
  height: 650px;
  padding: 5.5% 30px !important;
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
  color: #49494f;
}
.sentMessage::before {
  content: '';
  position: absolute;
  top: 10%;
  left: 0;
  height: 80%;
  width: 3px;
  background-color: #aaaaaa;
}
.sentMessage input {
  background: transparent;
  border: none;
  height: 50px;
  width: 400px;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding-left: 6px;
  outline: none;
  border: 2px solid rgba(101, 101, 101, 0.2) !important;
  margin-top: 25px !important;
  margin-bottom: 45px !important;
}
.sentMessage button {
  border-radius: 10px;
  font-size: 30px;
  opacity: 0.8;
  width: 100%;
  color: #f3f3f3;
  padding: 15px;
  background: #272727;
  cursor: pointer;
}

.contactInfo span {
  font-size: 55px;
  font-weight: 800;
  padding-bottom: 30px;
}
.contactInfo p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}
.contactInfo h5 {
  font-size: 17px;
  font-weight: 500;
  padding-bottom: 15px;
}
.contactInfo h6 {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  max-width: 230px;
}
.socialMediaWrapper {
  margin-top: 40px;
}
.icon {
  padding-right: 20px;
  color: black;
  font-size: 55px !important;
  cursor: pointer;
}
.icon:hover {
  color: #006fda;
  transition: all 0.3s ease-in;
}

.footer {
  height: 100px;
  border-top: 3px solid #aaaaaa;
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}
.footer p {
  color: #737373;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 6px 0 0 12px;
  cursor: pointer;
}
.footer a:hover {
  color: #006fda;
}

/* ADD ONS */

.packageContainer {
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 15px;
  height: fit-content;
  display: flex;
  flex-direction: column !important;
  background-color: white;
}
.heilightBtn {
  padding: 8px 10px;
  background-color: red;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
.subtext {
  font-size: 12px;
  color: gray;
}
.subText {
  font-size: 14px;
  color: gray;
  line-height: 22px;
}
.bundleListConatiner {
  border-radius: 8px;
  background-color: #f2f3f6;
  color: #333;
}
.addonConatiner {
  height: 8rem;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 14px;
  display: flex;
}
.addonSelectConatiner {
  background-color: #3969c91a !important;
}
.focusAddonContainer {
  border: 3px solid #ddd;
}
.addonConatiner img {
  height: 100%;
  max-width: 100px;
}
.addonDetails {
  display: flex;
  flex-direction: column !important;
  text-align: left !important;
  width: calc(100% - 200px);
  padding: 0 20px;
  height: 100%;
  justify-content: space-around;
}
.addonHeader {
  font-weight: 600;
  padding-bottom: 8px;
  color: #808080;
}
.addonNote {
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  font-weight: 450;
}
.addonAdd {
  height: 100%;
  width: 100px;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  align-items: flex-end;
}
.totalContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  background-color: white;
  box-shadow: 1px -15px 18px -21px rgba(0, 0, 0, 0.45), 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
}
.totalWrapper {
  width: 1065px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activeAddon {
  cursor: pointer;
  font-size: 30px;
  color: #006fda !important;
}
.addon {
  cursor: pointer;
  font-size: 30px;
  color: #808080;
}
.buyBtn {
  background-color: #006fda !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  min-width: 120px !important;
  border: 0.2px solid black !important;
}
.description {
  color: #222;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 14px;
}

/*        REGISTER PAGE       */

.mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fc;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #222;
}
.secondDiv {
  max-width: 1089px;
  height: 100%;
  align-content: flex-start;
  padding: 30px;
}
.headerContainer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  background-color: #f8f9fc;
}
.subContainer {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
}
.formContainer {
  display: flex;
  justify-content: center;
}
.formWrapper {
  max-width: 600px !important;
  background-color: white;
  border-radius: 8px;
  padding: 0 20px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.logo {
  height: 40px !important;
}
.errorMsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15px;
  font-size: 13px;
  color: rgb(181, 0, 0);
}
.textCenter {
  margin-top: 20px;
  text-align: center;
}
.forgotPasswordWrapper {
  font-size: 14px;
  text-align: end;
  color: #434a53;
  text-decoration: transparent;
}
.forgotPassword {
  text-decoration-color: transparent;
  color: #434a53;
}

@media screen and (max-width: 500px) {
  .addonNote {
    display: none;
  }
  .headContainer ul {
    display: none;
  }
  .boxContainer {
    width: 100%;
    height: fit-content;
    flex-direction: column !important;
  }
  .trialFeatureList {
    max-height: fit-content;
  }
}
