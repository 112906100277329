.navIcon {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 0px;
  vertical-align: middle;
}
.title {
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.username {
  font-size: 11px;
  margin-left: 8px;
  font-weight: bold;
  color: #03204c;
}
.Shop {
  font-size: 12px;
  margin-left: 8px;
  font-weight: bold;
  color: #03204c;
}
.navItem {
  padding: 10px;
  color: #fff;
  cursor: pointer;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 94%;
}
.navBarIconColor {
  color: #fff;
}
.navItem:link,
.navItem:visited {
  color: white;
  text-align: center;
  display: inline-block;
}
.navSectionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  color: #fff;
  cursor: pointer;
  background-color: #272c86;
}
.navSectionItem:link,
.navSectionItem:visited {
  color: white;
  text-align: center;
  display: inline-block;
}
.subMenu {
  width: 159px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-left: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 10px;
}
.profileSec {
  padding-bottom: 10px;
  padding-left: 10px;
  background: #72c7ec;
  display: flex;
  flex-direction: column;
}
.icons {
  padding: 2px;
  margin-right: 12px;
}
.subMenuContainer {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-left: 10px;
}
.subMenuItems {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  gap: 1px;
}
.navBarStepper {
  width: 13px;
  height: 16px;
  border: 10px;
  border-left: 2px solid #5c62a8;
  border-bottom: 2px solid #5c62a8;
  border-bottom-left-radius: 7px;
  position: absolute;
}
