.contentWrapper {
  height: 100%;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.menuTitle {
  font-size: 15px;
  font-weight: 400;
  padding-left: 8px;
  color: gray;
}
.changeable {
  background-color: #fff;
  border-top: 1px solid #d2d6de;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
}
.head {
  margin: 25px;
  font-size: 40px;
}
.note {
  font-size: 0.9rem;
  color: #999;
  margin-top: 16px !important;
}
.billContainer {
  min-width: 380px;
  width: 600px;
  max-width: 700px;
  height: fit-content;
}
.billContainer h4 {
  color: #222;
}
.billWrapper {
  border-radius: 12px;
  border: 3px dotted #808080;
  padding: 0 15px 15px 15px;
}
.hilight {
  color: rgb(70, 146, 75) !important;
}
.btn {
  width: 100% !important;
  border-radius: 14px !important;
  padding: 5px !important;
  text-transform: capitalize !important;
  font-size: 20px !important;
}

.subText {
  font-size: 1rem;
  color: #999;
}
.divider {
  width: 100%;
}
.subContainer {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  color: #222;
}
.subContainer:hover {
  background-color: #f1f8ffe6;
}

.subContainer ul {
  padding-left: 10px;
}
.subContainer li {
  padding-top: 6px;
  text-transform: capitalize;
}
.proceedBtn {
  width: 100%;
  padding: 6px;
  text-transform: capitalize;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  color: white;
  background-color: rgb(25 118 210 / 80%);
  cursor: pointer;
}
.proceedBtn:hover {
  background-color: #1976d2e6;
}
.chip {
  height: 28px !important;
}
