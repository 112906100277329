.body {
  padding: 16px;
  width: 355px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.label {
  font-size: 12px;
  font-weight: 500;
}
.select {
  display: flex;
  align-items: center;
  width: 140px;
  height: 39px;
}
.menuItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 14px 4px;
}
.MuiSelect-select {
  display: flex !important;
  align-items: center;
}
.MuiMenuItem-root {
  display: flex !important;
  align-items: center !important;
}
.MuiMenu-paper {
  display: block !important;
}
.MuiMenu-list {
  display: flex !important;
  flex-direction: column;
  padding: 12px;
}
.MuiList-root-MuiMenu-list {
  padding: 12px;
}
.dateTimePicker {
  display: flex;
  gap: 10px;
}
.MuiClock-root {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.MuiClock-root .MuiPickersLayout-actionBar {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  gap: 8px;
}
